import { PublicClientApplication } from "@azure/msal-browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import * as React from "react";
import { useAsync } from "react-use";
import { useTokenMaker } from "../hooks";
import { envName } from "../utils";
import mixpanel from "../utils/mixpanel";
import { useSnackbar } from "./SnackbarContainer";
import { createContainer } from "./StateContainer";

/**
 * Configure out app and connection to Microsoft authentication.
 */
export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: "0ba12eac-fc85-4b51-8089-2c741d94f404", // Application (client) ID
    authority: "https://login.microsoftonline.com/common/",
    /**
     * http://localhost:1234
     * https://staging.airplx.com
     * https://app.airplx.com
     */
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
});

export interface Identity {
  [x: string]: any;
  jwt?: string;
  sub?: string;
  iss?: string;
  aud?: string;
}

type Props = {
  forceSetIdentity?: Identity;
  children?: React.ReactNode;
};

/**
 * Parse a jwt into an identity>
 */
export const jwtToIdentity = (jwt: string) => {
  const decoded: Identity = jwt_decode(jwt);
  return { ...decoded, jwt };
};

export const LOCAL_STORAGE_KEY = `airplx/${envName()}/2023-02-02/identity`;
export const AIRPLX_TOKEN = `airplx/${envName()}/2023-02-02/token`;

/**
 * Create an identity container that can run the SSO login sequences with
 * online identity providers.
 *
 * Credentials are cached in local storage.
 *
 * An identity can be force set through props for testing or impersonation.
 */
const useIdentityContainerState = (props: Props) => {
  const snackbar = useSnackbar();
  const { verifyJWT } = useTokenMaker();
  const [identity, setIdentity] = React.useState<Identity>(
    props.forceSetIdentity ??
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "{}")
  );
  const [airplxToken, setAirplxToken] = React.useState<string>(
    localStorage.getItem(AIRPLX_TOKEN)
  );
  const airplxIdentity = Boolean(airplxToken)
    ? (jwt_decode(airplxToken) as Identity)
    : {};

  const refreshAirplxToken = async (jwt?: string) => {
    const token = await verifyJWT(jwt ?? identity.jwt);
    if (!token) {
      // usually when this happens, the user has a really old token. we're just going
      // to log them out and make them re-authenticate.
      setIdentity(undefined);
      // nuke everything in local storage
      localStorage.clear();
      snackbar.notify({
        text:
          "We need to validate your account credentials. Please login with your SSO provider.",
        severity: "error",
      });
      window.location.href = "/#/login";
      return;
    }
    setAirplxToken(token);
    localStorage.setItem(AIRPLX_TOKEN, token ? token : "");
    if (!token) {
      snackbar.notify({
        text:
          "You attemped to login with an unauthorized account. Please try another account.",
        severity: "error",
      });
    }
  };

  /**
   * When your identity changes -- cache it in local storage.
   */
  useAsync(async () => {
    // check for expired tokens
    if (identity.exp < Date.now() / 1000) {
      setIdentity(undefined);
      return;
    }
    if (airplxIdentity.exp < Date.now() / 1000) {
      await refreshAirplxToken();
    }
    if (identity && identity.jwt) {
      mixpanel.people.set("msftIdentity", identity);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(identity));
      if (!airplxToken) {
        await refreshAirplxToken();
        window.location.href = "/#/";
      }
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, [identity]);

  /**
   * MixPanel user tracking
   */
  React.useEffect(() => {
    if (!airplxIdentity || !airplxIdentity.userid) {
      return;
    }
    mixpanel.identify(airplxIdentity.userid);
    mixpanel.people.set({ $email: airplxIdentity.email });
    mixpanel.people.set({ $name: identity?.account?.name });
  }, [airplxToken]);

  /**
   * Ask Microsoft to get us an identity token.
   */
  const loginWithMicrosoft = async () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(AIRPLX_TOKEN);
    return msalInstance
      .loginPopup({ scopes: ["openid"] })
      .then(async (data) => {
        const identity = {
          ...data,
          jwt: data.idToken,
        };
        await refreshAirplxToken(data.idToken);
        setIdentity(identity);
      });
  };

  /**
   * Ask Google to get us an identity token.
   */
  const loginWithGoogle = async (token: string, identity: Identity) => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(AIRPLX_TOKEN);
    await refreshAirplxToken(token);
    setIdentity({
      ...identity,
      jwt: token,
    });
  };

  /**
   * Clear out identity.
   */
  const logout = async () => {
    setIdentity(undefined);
    setAirplxToken(null);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(AIRPLX_TOKEN);
    window.location.reload();
  };

  return {
    isLoggedIn: Boolean(airplxToken),
    identity,
    airplxIdentity,
    airplxToken,
    loginWithMicrosoft,
    loginWithGoogle,
    refreshAirplxToken,
    logout,
  };
};

const IdentityContainerState = createContainer(useIdentityContainerState);

/**
 * Set up an identity container scope. This should really only be in the
 * application once -- and way way up at the root.
 */
export const IdentityContainer: React.FC<Props> = ({ children, ...props }) => {
  return (
    <IdentityContainerState.Provider initialState={props}>
      <GoogleOAuthProvider clientId="766877242042-4klesl9nm4aro0glc74tp588qc1fqtcq.apps.googleusercontent.com">
        {children}
      </GoogleOAuthProvider>
    </IdentityContainerState.Provider>
  );
};

/**
 * Hook into the current identity context.
 */
export const useIdentity = IdentityContainerState.useContainer;
